const CryptoJS = require("crypto-js");

export function encrypt(jsonString: string): string {
  const AESKEY = process.env.REACT_APP_ENCRYPTION_AES_KEY;
  const IVSPEC = process.env.REACT_APP_ENCRYPTION_IVSPEC;

  try {
    const key = CryptoJS.enc.Utf8.parse(AESKEY);
    const iv = CryptoJS.enc.Utf8.parse(IVSPEC);

    const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  } catch (error) {
    throw new Error(`Encryption failed: ${(error as Error).message}`);
  }
}

export function decrypt(responseString: string): string {
  const AESKEY = process.env.REACT_APP_ENCRYPTION_AES_KEY;
  const IVSPEC = process.env.REACT_APP_ENCRYPTION_IVSPEC;

  try {
    const key = CryptoJS.enc.Utf8.parse(AESKEY);
    const iv = CryptoJS.enc.Utf8.parse(IVSPEC);

    const decrypted = CryptoJS.AES.decrypt(responseString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return CryptoJS.enc.Utf8.stringify(decrypted);
  } catch (error) {
    throw new Error(`Decryption failed: ${(error as Error).message}`);
  }
}

// Helper function to convert a hexadecimal string to a byte array
export function hexStringToByteArray(hexString: string) {
  if (!hexString) {
    throw new Error("Input string cannot be null or empty.");
  }
  const byteArray = [];
  for (let i = 0; i < hexString.length; i += 2) {
    byteArray.push(parseInt(hexString.substr(i, 2), 16));
  }
  return Buffer.from(byteArray);
}

// Helper function to convert a byte array to a hexadecimal string
export function byteArrayToHexString(byteArray: string) {
  if (!byteArray) {
    throw new Error("Input byte array cannot be null or undefined.");
  }
  return Buffer.from(byteArray).toString("hex");
}
