export const routePaths = Object.freeze({
  BASE_URL: "/",
  SIGN_IN: "/sign-in",
  TRESURY_PRODUCTS: "/treasury-products",
  PURCHASES: "/purchases",
  PURCHASE_REQUESTS: "/purchase-requests",
});

export const routesAndRoles = [
  {
    route: routePaths.TRESURY_PRODUCTS,
    roles: [1],
    bounceRoute: routePaths.PURCHASE_REQUESTS,
  },
  {
    route: routePaths.PURCHASES,
    roles: [1],
    bounceRoute: routePaths.PURCHASE_REQUESTS,
  },
  {
    route: routePaths.PURCHASE_REQUESTS,
    roles: [2],
    bounceRoute: routePaths.TRESURY_PRODUCTS,
  },
];
