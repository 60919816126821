import { useContext, useState } from "react";
import classes from "./SignIn.module.css";
import fcmbLogo from "../../Assets/Images/fcmbLogo.svg";
import Card from "../../Components/Card/Card";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import { AuthContext } from "../../Context/AuthContext";
import { OTP_LENGTH } from "../../Utilities/constants";

const SignIn = () => {
  // States
  const [userDetails, setUserDetails] = useState({
    emailAddress: "",
    password: "",
    otp: "",
  });

  // COntext
  const { signIn, requestState } = useContext(AuthContext);

  return (
    <section className={classes.container}>
      <img src={fcmbLogo} alt="FCMB Logo" />

      <Card className={classes.formContainer} borderRadius="16px">
        <h1>Sign in with your account credentials</h1>

        <form>
          <Input
            label="Username"
            placeholder="Enter your username"
            isRequired
            errorMessage="Please fill in a valid username"
            type="email"
            name="emailAddress"
            value={userDetails.emailAddress}
            onChange={(e) => inputChangeHandler(e, setUserDetails)}
          />

          <Input
            label="Password"
            placeholder="Enter your password"
            isRequired
            errorMessage="Please fill in a valid passord"
            type="password"
            name="password"
            value={userDetails.password}
            onChange={(e) => inputChangeHandler(e, setUserDetails)}
          />

          <Input
            label="Token"
            placeholder="Enter your token"
            isRequired
            errorMessage="Please fill in a valid token"
            type="text"
            name="otp"
            value={userDetails.otp}
            onChange={(e) => inputChangeHandler(e, setUserDetails)}
          />

          <Button
            disabled={
              !userDetails?.emailAddress ||
              !userDetails.password ||
              userDetails?.otp.length < OTP_LENGTH
            }
            onClick={(e) => {
              e.preventDefault();
              signIn(userDetails);
            }}
            loading={requestState.isLoading}
          >
            Sign in
          </Button>
        </form>
      </Card>
    </section>
  );
};

export default SignIn;
