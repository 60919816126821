import React, { lazy, Suspense } from "react";
import SignIn from "../Containers/SignIn/SignIn";
import { routePaths } from "./routePaths";
import Loader from "../Components/Loader/Loader";

const TresuryProducts = lazy(
  () => import("../Containers/TresuryProducts/TresuryProducts")
);
const Purchases = lazy(() => import("../Containers/Purchases/Purchases"));

const PurchaseRequests = lazy(
  () => import("../Containers/PurchaseRequests/PurchaseRequests")
);

type routesType = {
  route: string;
  element: React.ReactNode;
  properties: any[] | null;
  name: string;
  roles?: number[];
}[];

export const routes: routesType = [
  {
    route: routePaths.SIGN_IN,
    element: <SignIn />,
    properties: null,
    name: "Sign In",
    roles: [1, 2],
  },

  {
    route: routePaths.TRESURY_PRODUCTS,
    element: (
      <Suspense fallback={<Loader />}>
        <TresuryProducts />
      </Suspense>
    ),
    properties: ["isProtected", "isSideNavRoute"],
    name: "Treasury Proucts",
    roles: [1],
  },

  {
    route: routePaths.PURCHASES,
    element: (
      <Suspense fallback={<Loader />}>
        {" "}
        <Purchases />
      </Suspense>
    ),
    properties: ["isProtected", "isSideNavRoute"],
    name: "Purchases",
    roles: [1],
  },

  {
    route: routePaths.PURCHASE_REQUESTS,
    element: (
      <Suspense fallback={<Loader />}>
        {" "}
        <PurchaseRequests />
      </Suspense>
    ),
    properties: ["isSideNavRoute", "isProtected"],
    name: "Purchase Requests",
    roles: [2],
  },
];
