import { Navigate, Outlet, useLocation } from "react-router-dom";
import { routesAndRoles } from "../../Utilities/routePaths";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

const RequireRole = () => {
  // Context
  const { user } = useContext(AuthContext);

  // Router
  const location = useLocation();

  //   Utils
  const activeRoute = routesAndRoles.find(
    (data) => data?.route === location?.pathname
  );
  const userRole = user?.data?.data?.roleId;

  return (
    <>
      {activeRoute?.roles?.includes(userRole) ? (
        <Outlet />
      ) : (
        <Navigate
          to={activeRoute?.bounceRoute as string}
          replace={true}
          state={location.pathname}
        />
      )}
    </>
  );
};

export default RequireRole;
