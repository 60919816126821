import { useToast } from "../Context/ToastContext";
import { sessionStorageHandler } from "../HelperFunctions/sessionStorageHandler";
import { useNavigate } from "react-router-dom";
import { SESSION_STORAGE_AUTH_KEY } from "../Utilities/constants";
import { routePaths } from "../Utilities/routePaths";

const useError = () => {
  // Context
  const { showToast } = useToast();

  // Router
  const navigate = useNavigate();

  // Utils
  const logout = () => {
    sessionStorageHandler("delete", SESSION_STORAGE_AUTH_KEY as string);
    sessionStorageHandler("delete", SESSION_STORAGE_AUTH_KEY as string);
    navigate(routePaths.SIGN_IN);
  };

  const errorFlowFunction = (err: any) => {
    if (err?.response?.status === 401) {
      showToast("Unauthorized, Please sign in again", "error");
      logout();
      return;
    }

    showToast(
      err?.response?.data?.error?.message ||
        err?.response?.data?.description ||
        err?.response?.data?.Error?.Messages
        ? err?.response?.data?.Error?.Messages[0]
        : err?.response?.data?.Error
        ? err?.response?.data?.Error[0]
        : err?.response?.data?.error?.messages[0],
      "error"
    );
  };

  return { errorFlowFunction };
};

export default useError;
