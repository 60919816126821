import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./Utilities/routes";
import { routePaths } from "./Utilities/routePaths";
import React from "react";
import RequireAuth from "./Components/RequireAuth/RequireAuth";
import RequireRole from "./Components/RequireRole/RequireRole";

function App() {
  return (
    <Routes>
      <Route
        path={routePaths.BASE_URL}
        element={<Navigate to={routePaths.SIGN_IN} />}
      />
      {routes.map((route, i) => {
        if (route?.properties?.includes("isProtected")) {
          return (
            <Route element={<RequireAuth />} key={i}>
              <Route element={<RequireRole />} key={i}>
                <Route path={route.route} element={route.element} />
              </Route>
            </Route>
          );
        } else
          return (
            <React.Fragment key={i}>
              <Route path={route.route} element={route.element} />
            </React.Fragment>
          );
      })}
    </Routes>
  );
}

export default App;
